<template>
  <dashboard>
    <el-row :gutter="15" class="mb-3">
      <el-col :xs="20" :sm="16">
        <h4 class="ml-1 mb-b bold orange-text">My bookmarked freelancers</h4>
      </el-col>
      <el-col :xs="4" :sm="8">
        <div class="add-job text-right">
          <router-link to="/employer/freelancers">
            <el-button type="warning" class="title-button-mobile">
              <i class="el-icon-search"></i>
              <span class="hidden-xs">Search freelancers</span>
            </el-button>
          </router-link>
        </div>
      </el-col>
    </el-row>
    <hr />
    <el-row :gutter="15">
      <el-col :xs="24">
        <div v-if="loading" class="bg-white text-center py-5">
          <img src="/images/logo.png" height="50" id="loader">
        </div>
        <template v-else>
          <div v-if="bookmarkedFreelancers.data && bookmarkedFreelancers.data.length > 0" class="freelancer-profile">
            <router-link v-for="freelancer in bookmarkedFreelancers.data" :key="freelancer.id" :to="`/employer/profile/${freelancer.freelancer.id}`" exact class="no-underline-hover">
              <el-card>
                <el-row :gutter="15" type="flex">
                  <el-col :span="3">
                    <img :src="profilePhotoUrl(freelancer.freelancer)" class="w-100" />
                  </el-col>
                  <el-col :span="18" class="align-self-center">
                    <p class="bold small mb-1" :class="{blur:isOnFreePlan}">
                      {{ freelancer.freelancer.full_name }}
                    </p>
                    <el-rate v-model="value" disabled></el-rate>
                    <p class="small mb-2 mt-1">
                      <span class="bold gray-text">Intro:</span>
                      {{ freelancer.freelancer.profile.intro }}
                    </p>
                    <p class="small mb-2">
                      <span class="bold gray-text">Skills:</span>
                    </p>
                    <el-tag class="mr-1 mb-1" v-for="skill in freelancer.freelancer.freelancer_skills" :key="skill.id" type="info">{{ skill.skill.skill_name }}</el-tag>
                  </el-col>
                  <el-col :span="3" class="text-center text-lg-right">
                    <el-button type="warning" size="small" class="" plain circle icon="el-icon-more"></el-button>
                  </el-col>
                </el-row>
              </el-card>
            </router-link>
            <el-card>
              <div v-if="totalResults > perPage" class="job-pagination text-center mb-0">
                <el-pagination background :current-page.sync="pageNum" layout="prev, pager, next" :total="totalResults" :page-size="perPage" @current-change="pageChange"></el-pagination>
              </div>
            </el-card>
          </div>

          <div v-else class="text-center bg-white py-5">
            You don't have any bookmarked Freelancers.
          </div>
        </template>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
import Dashboard from '~/components/Dashboard/Dashboard';
import { mapGetters, mapActions } from 'vuex';
import showsProfilePhoto from '~/mixins/showsProfilePhoto';

export default {
  components: {
    Dashboard
  },
  data() {
    return {
      value: 5,
      options: [
        {
          value: 'Option1',
          label: 'Option1'
        },
        {
          value: 'Option2',
          label: 'Option2'
        },
        {
          value: 'Option3',
          label: 'Option3'
        },
        {
          value: 'Option4',
          label: 'Option4'
        },
        {
          value: 'Option5',
          label: 'Option5'
        }
      ],
      value1: [],
      value2: [],
      value3: [],
      pageNum: 1,
      perPage: 1,
      totalResults: 0,
      loading: true,
    };
  },

  computed: {
    ...mapGetters({
      bookmarkedFreelancers: 'getEmployerBookmarkedFreelancers',
      isOnFreePlan:'subscription/isOnFreePlan'
    })
  },
  
	mixins: [showsProfilePhoto],

  created() {
    if (this.$route.query.page) {
      this.pageNum = parseInt(this.$route.query.page);
    }
    this.getPaginatedFreelancers();
  },
  methods: {
    ...mapActions({
      getFreelancers: 'getEmployerBookmarkedFreelancers'
    }),
    async getPaginatedFreelancers() {
      let data = await this.getFreelancers(this.pageNum);
      this.loading = false;
      if (data) {
        this.totalResults = data.total;
        this.perPage = data.per_page;
        this.freelancers = data.data;
      }
    },
    pageChange(page) {
      window.history.pushState(null, null, `?page=${page}`);
      this.getFreelancers(page);
    }
  }
};
</script>
<style lang="scss">
.jobfeed {
  .search {
    padding: 30px;
  }
  .el-input-group__append button.el-button,
  .el-input-group__append,
  .el-input-group__prepend {
    color: #fff;
    background-color: #e87722;
    border-color: #e87722;
  }
  .el-pagination {
    text-align: center;
  }
}
#loader {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
</style>
